import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  title: '',
  content: '',
  onConfirm: '',
  onCancel: null,
  onClose: ''
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setContent: (state, action) => {
      state.content = action.payload;
    },
    setOnConfirm: (state, action) => {
      state.onConfirm = action.payload;
    },
    setOnCancel: (state, action) => {
      state.onCancel = action.payload;
    },
    setOnClose: (state, action) => {
      state.onClose = action.payload;
    },
    reset: () => initialState
  },
});

export const { setTitle, setContent, setOnConfirm, setOnCancel, setOnClose, reset } = modalSlice.actions;

export const selectTitle = state => state.modal.title;
export const selectContent = state => state.modal.content;
export const selectOnConfirm = state => state.modal.onConfirm;
export const selectOnCancel = state => state.modal.onCancel;
export const selectOnClose = state => state.modal.onClose;

export default modalSlice.reducer;

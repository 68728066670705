import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000
});
const token = localStorage.getItem('token');
if (token)
  instance.defaults.headers.common['Authorization'] = localStorage.getItem('token');

export default instance;
import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import CacheRoute, { CacheSwitch } from 'react-router-cache-route'

import ScrollToTop from './ScrollToTop'


const Home = lazy(() => import(/* webpackChunkName: "HomePage" */ 'pages/Home'));
const About = lazy(() => import(/* webpackChunkName: "AboutPage" */ 'pages/About'));
const Contact = lazy(() => import(/* webpackChunkName: "ContactPage" */ 'pages/Contact'));
const Categories = lazy(() => import(/* webpackChunkName: "CategoriesPage" */ 'pages/Categories'));
const Category = lazy(() => import(/* webpackChunkName: "CategoryPage" */ 'pages/Category'));
const Product = lazy(() => import(/* webpackChunkName: "ProductPage" */ 'pages/Product'));
const Checkout = lazy(() => import(/* webpackChunkName: "CheckoutPage" */ 'pages/Checkout'));
const Faqs = lazy(() => import(/* webpackChunkName: "FaqsPage" */ 'pages/Faqs'));

const Admin = lazy(() => import(/* webpackChunkName: "AdminPage" */ 'pages/Admin'));
const AdminCategories = lazy(() => import(/* webpackChunkName: "AdminCategoriesPage" */ 'pages/Admin/AdminCategories'));
const AdminProducts = lazy(() => import(/* webpackChunkName: "AdminProductsPage" */ 'pages/Admin/AdminProducts'));
const AdminInstagram = lazy(() => import(/* webpackChunkName: "AdminInstagramPage" */ 'pages/Admin/AdminInstagram'));
const AdminFaqs = lazy(() => import(/* webpackChunkName: "AdminFaqsPage" */ 'pages/Admin/AdminFaqs'));
const AdminOrders = lazy(() => import(/* webpackChunkName: "AdminOrdersPage" */ 'pages/Admin/AdminOrders'));

const ROUTES = [
  {
    path: '/',
    key: 'home',
    exact: true,
    component: Home,
    cached: true
  },
  {
    path: '/about',
    key: 'about',
    exact: true,
    component: About,
  },
  {
    path: '/contact',
    key: 'contact',
    exact: true,
    component: Contact,
  },
  {
    path: '/categories',
    key: 'categories',
    exact: true,
    component: Categories,
  },
  {
    path: '/categories/:id',
    key: 'category',
    exact: true,
    component: Category,
    cached: true
  },
  {
    path: '/products/:id',
    key: 'product',
    exact: true,
    component: Product,
  },
  {
    path: '/checkout',
    key: 'checkout',
    exact: true,
    component: Checkout,
  },
  {
    path: '/faqs',
    key: 'faqs',
    exact: true,
    component: Faqs,
  },
  {
    path: '/admin',
    key: 'admin',
    component: Admin,
    routes: [
      {
        path: "/admin",
        key: "dashboard",
        exact: true,
        component: () => <div>Dashboard</div>,
      },
      {
        path: "/admin/test",
        key: "test",
        exact: true,
        component: () => <div>Sub route</div>,
      },
      {
        path: "/admin/styles",
        key: "adminStyle",
        exact: true,
        component: () => <div>I am styles</div>,
      },
      {
        path: "/admin/categories",
        key: "adminCategories",
        exact: true,
        component: AdminCategories,
      },
      {
        path: "/admin/products",
        key: "adminProducts",
        exact: true,
        component: AdminProducts,
      },
      {
        path: "/admin/instagram-posts",
        key: "AdminInstagram",
        exact: true,
        component: AdminInstagram,
      },
      {
        path: "/admin/faqs",
        key: "AdminFaqs",
        exact: true,
        component: AdminFaqs,
      },
      {
        path: "/admin/orders",
        key: "AdminOrders",
        exact: true,
        component: AdminOrders,
      },
      {
        path: "*",
        key: "admin-404",
        component: () => <div className="text-center">404</div>,
      },
    ]
  },
  /* {
    path: "/app",
    key: "APP",
    component: () => <h1>App</h1>,
    routes: [
      {
        path: "/app",
        key: "APP_ROOT",
        exact: true,
        component: () => <h1>App Index</h1>,
      },
      {
        path: "/app/page",
        key: "APP_PAGE",
        exact: true,
        component: () => <h1>App Page</h1>,
      },
      { //auth
    path: "/app",
    key: "APP",
    component: props => {
      if (!localStorage.getItem("user")) {
        alert("You need to log in to access app routes");
        return <Redirect to={"/"} />;
      }
      return <RenderRoutes {...props} />;
    },
    ],
  }, */
];

export default ROUTES;

function RouteWithSubRoutes (route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => 
        <route.component
          {...props}
          routes={route.routes}
        />}
    />
  );
}

export function RenderRoutes ({ routes }) {
  return (
    <Suspense fallback={<div>{/* Loading... */}</div>}>
      <ScrollToTop>
        <CacheSwitch>
          {routes.map((route) => {
            if(route.cached)
              return (
                <CacheRoute
                  key={route.key}
                  {...route}
                />
              )
            else
              return(
                <RouteWithSubRoutes
                  key={route.key}
                  {...route}
                />
              )
          })}
          <Route component={() => <div className="container"><h1 className="text-center">404</h1></div>} />
        </CacheSwitch>
      </ScrollToTop>
    </Suspense>
  );
}

RenderRoutes.propTypes = {
  routes: PropTypes.array,
};
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
  editProduct: null,
  refreshProduct: false
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProductList: (state, action) => {
      state.list = action.payload;
    },
    setEditProduct: (state, action) => {
      state.editProduct = action.payload ? {...action.payload}  : null;
    },
    toggleRefreshProduct: (state) => {
      state.refreshProduct = !state.refreshProduct;
    },
    reset: () => initialState
  },
});

export const { setProductList, setEditProduct, toggleRefreshProduct, reset } = productSlice.actions;

export const selectProductList = state => state.product.list;
export const selectEditProduct = state => state.product.editProduct;
export const selectRefreshProduct = state => state.product.refreshProduct;

export default productSlice.reducer;

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import counterReducer from './slices/counterSlice'
import authReducer from './slices/authSlice'
import userReducer from './slices/userSlice'
import deviceReducer from './slices/deviceSlice'
import uiReducer from './slices/uiSlice'
import modalReducer from './slices/modalSlice'
import categoryReducer from './slices/categorySlice'
import productReducer from './slices/productSlice'
import cartReducer from './slices/cartSlice'
import instagramReducer from './slices/instagramSlice'
import orderReducer from './slices/orderSlice'
//import { useReducer } from 'react'

export default configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    user: userReducer,
    device: deviceReducer,
    ui: uiReducer,
    modal: modalReducer,
    category: categoryReducer,
    product: productReducer,
    cart: cartReducer,
    instagram: instagramReducer,
    order: orderReducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['modal/setOnConfirm', 'modal/setOnCancel', 'modal/setOnClose', 'category/setEditCategory',
        'product/setEditProduct', 'product/toggleRefreshProduct', 'instagram/setEditInstagramPost']
    }
  })
});

import * as React from "react";

function SvgInstagramIcon(props) {
  return (
    <svg data-name="Group 1091" width={36.016} height={36.017} {...props}>
      <g data-name="Group 1090" fill="#fff">
        <path
          data-name="Path 1012"
          d="M26.254 18.015c0-1.526.024-3.052-.006-4.577a3.638 3.638 0 00-3.6-3.716q-4.638-.091-9.278 0a3.628 3.628 0 00-3.6 3.6q-.073 4.638 0 9.28a3.618 3.618 0 003.544 3.581q4.7.069 9.4 0a3.624 3.624 0 003.527-3.53c.044-1.544.009-3.092.009-4.638zm-8.243 5.253a5.263 5.263 0 115.27-5.226 5.243 5.243 0 01-5.27 5.226zm5.455-9.5a1.234 1.234 0 111.192-1.22 1.238 1.238 0 01-1.192 1.22z"
        />
        <path
          data-name="Path 1013"
          d="M17.972 14.569a3.388 3.388 0 103.422 3.394 3.391 3.391 0 00-3.422-3.394z"
        />
        <path
          data-name="Path 1014"
          d="M0 0v36.017h36.016V0zm27.945 22.063a5.716 5.716 0 01-5.819 5.878c-1.372.015-2.743 0-4.115 0h-4a5.712 5.712 0 01-5.937-5.88 654.568 654.568 0 010-8.171 5.708 5.708 0 015.875-5.819q4.056-.01 8.111 0a5.724 5.724 0 015.879 5.879q.017 4.056.006 8.112z"
        />
      </g>
    </svg>
  );
}

export default SvgInstagramIcon;

import React from 'react'

import CartIcon from 'svg/build/CartIcon'

import styles from './CartTracker.module.scss'

import { useSelector } from 'react-redux'

import { selectTotalAmount } from 'store/slices/cartSlice'

export default function CartTracker () {
  const amount = useSelector(selectTotalAmount);

  return (
    <span className="position-relative">
      <span className={`${styles.CartCounter} ${amount !== 0 ? styles.Active : ''}`}>{amount}</span>
      <CartIcon className={`${styles.CartIcon}`} />
    </span>
  );
}
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  windowWidth: undefined,
  desktopWidth: 1199,
  laptopWidth: 991,
  tabletWidth: 767,
  bigPhoneWidth: 575
};

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setWindowWidth: (state, action) => {
      state.windowWidth = action.payload;
    },
    reset: () => initialState
  },
});

export const { setWindowWidth, reset } = deviceSlice.actions;

export const selectWindowWidth = state => state.device.windowWidth;
export const selectIsDesktop = state => state.device.windowWidth >= state.device.desktopWidth;
export const selectIsLaptop = state => state.device.windowWidth >= state.device.laptopWidth && state.device.windowWidth < state.device.desktopWidth;
export const selectIsTablet = state => state.device.windowWidth >= state.device.tabletWidth && state.device.windowWidth < state.device.laptopWidth;
export const selectIsBigPhone = state => state.device.windowWidth >= state.device.bigPhoneWidth && state.device.windowWidth < state.device.tabletWidth;
export const selectIsSmallPhone = state => state.device.windowWidth < state.device.bigPhoneWidth;
export const selectIsPhone = state => state.device.windowWidth < state.device.tabletWidth;
export const selectIsPhoneOrTablet = state => (state.device.windowWidth < state.device.tabletWidth) || (state.device.windowWidth >= state.device.tabletWidth && state.device.windowWidth < state.device.laptopWidth);

export default deviceSlice.reducer;

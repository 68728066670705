import { createSlice } from '@reduxjs/toolkit'
import { getCategoriesService } from 'services/categoriesService'
const initialState = {
  list: [],
  editCategory: null
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategoryList: (state, action) => {
      state.list = action.payload;
    },
    setEditCategory: (state, action) => {
      state.editCategory = action.payload;
    },
    reset: () => initialState
  },
});

export const { setCategoryList, setEditCategory, reset } = categorySlice.actions;

export const selectCategoryList = state => state.category.list;
export const selectEditCategory = state => state.category.editCategory;

export const getCategoriesAsync = () => async dispatch => {
  const response = await getCategoriesService();
  if (response.error)
    return response;

  dispatch(setCategoryList(response.data));

  return response;
};

export default categorySlice.reducer;

import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  editInstagramPost: null,
  refreshInstagramPosts: false
};

export const instagramSlice = createSlice({
  name: 'instagram',
  initialState,
  reducers: {
    setEditInstagramPost: (state, action) => {
      state.editInstagramPost = action.payload;
    },
    toggleRefreshInstagramPosts: (state) => {
      state.refreshInstagramPosts = !state.refreshInstagramPosts;
    },
    reset: () => initialState
  },
});

export const { setEditInstagramPost, toggleRefreshInstagramPosts, reset } = instagramSlice.actions;

export const selectEditInstagramPost = state => state.instagram.editInstagramPost;
export const selectRefreshInstagramPosts = state => state.instagram.refreshInstagramPosts;

export default instagramSlice.reducer;

import { createSlice } from '@reduxjs/toolkit'

import { openCart } from 'store/slices/uiSlice'

const initialState = {
  data: {},
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.data[action.payload.id] = action.payload;
      saveToLocalStorage(state.data);
    },
    setCart: (state, action) => {
      state.data = action.payload;
      saveToLocalStorage(state.data);
    },
    removeItem: (state, action) => {
      delete state.data[action.payload];
      saveToLocalStorage(state.data);
    },
    clear: () => initialState
  },
});

export const addItemAsync = (data) => async dispatch => {
  dispatch(addItem(data));
  dispatch(openCart());
};

export const { addItem, setCart, removeItem, clear } = cartSlice.actions;

export const selectData = state => state.cart.data;
export const selectTotalAmount = state => !state.cart.data || Object.keys(state.cart.data).length === 0 ?
  0 :  Object.values(state.cart.data).reduce((t, {amount}) => t + amount, 0);
export const selectTotalCost = state => parseFloat(
  !state.cart.data || Object.keys(state.cart.data).length === 0 ?
    0 :  Object.values(state.cart.data).reduce((t, {amount, price}) => t + amount*price, 0).toFixed(2)
);

export default cartSlice.reducer;

function saveToLocalStorage (data) {
  localStorage.setItem('cart', JSON.stringify(
    { 
      items: {...data}, 
      timestamp: new Date().getTime()
    }
  ));
}

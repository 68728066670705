import { createSlice } from '@reduxjs/toolkit'
import { setIsLoggedIn } from 'store/slices/authSlice'
import { reInitHttpHeader } from 'services/authService'
import { getUserDataService } from 'services/usersService'

const initialState = {
  id: null,
  name: '',
  userInfo: {}
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setId: (state, action) => {
      state.id = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    reset: () => initialState
  },
});

export const { setId, setName, setUserInfo, reset } = userSlice.actions;

export const selectId = state => state.user.id;
export const selectName = state => state.user.name;
export const selectUserInfo = state => state.user.userInfo;

export const getUserDataAsync = () => async dispatch => {
  const response = await getUserDataService();
  if (response.error) {
    dispatch(setIsLoggedIn(false));
    dispatch(reset());
    localStorage.removeItem('token');
    reInitHttpHeader();

    return response;
  }

  dispatch(setId(response.data.id));
  dispatch(setName(response.data.name));

  return response;
};

export default userSlice.reducer;

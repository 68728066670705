import React, { Suspense, useState, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { ToastProvider } from 'react-toast-notifications'
import { QueryParamProvider } from 'use-query-params';
import { Route, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { getUserDataAsync, setUserInfo } from 'store/slices/userSlice'
import { selectIsLoggedIn } from 'store/slices/authSlice'
import { selectIsPhoneOrTablet, setWindowWidth } from 'store/slices/deviceSlice'
import { selectLeftNavOpened, closeLeftNavigation, selectCartOpened, closeCart } from 'store/slices/uiSlice'
import { getCategoriesAsync } from 'store/slices/categorySlice'
import { setCart } from 'store/slices/cartSlice'

import ROUTES, { RenderRoutes } from './routes'

import styles from './App.module.scss'
import Navigation from 'components/Navigation/Navigation'
import LeftNav from 'components/Navigation/LeftNav'
import Modal from 'components/Misc/Modal/Modal'
import Footer from 'components/Misc/Footer'
import CartNavigation from 'components/Cart/CartNavigation'
import InstagramFeed from 'components/Instagram/InstagramFeed'

import ReactGA from 'react-ga4'

function App () {
  const [checkAuthStatus, setCheckAuthStatus] = useState(false);

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const leftNavOpened = useSelector(selectLeftNavOpened);
  const cartOpened = useSelector(selectCartOpened);
  const isPhoneOrTablet = useSelector(selectIsPhoneOrTablet);

  const location = useLocation();

  const dispatch = useDispatch();
  const closeSideNavigation = (e) => {
    if(e) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    if(leftNavOpened)
      dispatch(closeLeftNavigation());
    if(cartOpened)
      dispatch(closeCart());
  }
  const pageViewGA = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }
  useEffect(() => {
    async function getUserData () {
      if (isLoggedIn)
        await dispatch(getUserDataAsync());
      setCheckAuthStatus(true);
    }

    function handleResize () {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      dispatch(setWindowWidth(window.innerWidth));
    }

    function checkCart () {
      const cart = JSON.parse(localStorage.getItem('cart'));
      if(!cart || !cart.timestamp)
        return;
        
      const diffInHours = (new Date().getTime() - cart.timestamp) / 3600000;
      if(diffInHours > 2)
        return localStorage.removeItem('cart');

      dispatch(setCart(cart.items))
    }

    function checkUserInfo () {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if(!userInfo || !userInfo.name)
        return;
      dispatch(setUserInfo(userInfo));
    }

    function setupGA () {
      ReactGA.initialize(process.env.REACT_APP_GA_ID, {debug: process.env.NODE_ENV === 'development'});
    }

    dispatch(getCategoriesAsync());
    getUserData();
    handleResize();
    checkCart();
    checkUserInfo();
    if(process.env.NODE_ENV === 'production')
      setupGA();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [])

  useEffect(() => {
    closeSideNavigation();
    if(process.env.NODE_ENV === 'production')
      pageViewGA();
  }, [location])
  return (
    /* <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div> */
    <Suspense fallback={null}>
      <HelmetProvider>
        <Helmet>
          <meta
            name="description"
            content="Unique urban goods shops made in Sarajevo dedicated to making products you love"
          />
          <meta
            property="og:title"
            content="Stranac"
          />
          <meta
            property="og:description"
            content="Unique urban goods shops made in Sarajevo dedicated to make the products you love"
          />
          <meta
            property="og:image"
            content={`${process.env.REACT_APP_ROOT_URL}/img/stranac-logo-black.jpg`}
          />
          <meta
            property="og:type"
            content="website"
          />
          <title>Stranac</title>
        </Helmet>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ToastProvider>
            {checkAuthStatus &&
            <div className={styles.App}>
              {isPhoneOrTablet && <LeftNav />}
              {leftNavOpened &&
                <div
                  className={`${styles.LeftNavCloseArea}`}
                  onClick={closeSideNavigation}
                />
              }
              
              <Navigation />
              <CartNavigation />
              {!isPhoneOrTablet &&
                <div
                  className={`${styles.CartCloseArea} ${cartOpened ?  styles.CartCloseAreaActive : ''}`}
                  onClick={closeSideNavigation}
                />
              }
              <div
                className={`${styles['App-content']} ${leftNavOpened ?  styles['App-content--left-nav-active'] : ''}`}
                onClick={closeSideNavigation}
              >
                <RenderRoutes routes={ROUTES} />
              </div>
              <Modal />
              <div className={`${styles.FooterOuter} ${leftNavOpened ?  styles.FooterOuterLeftNavActive : ''}`}>
                <InstagramFeed />
              </div>
              <div
                className={`${styles.FooterOuter} ${leftNavOpened ?  styles.FooterOuterLeftNavActive : ''}`}
              >
                <Footer />
              </div>
            </div>
            }
          </ToastProvider>
        </QueryParamProvider>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;

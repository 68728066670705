import React from 'react'
import PropTypes from 'prop-types'

import LazyLoad from 'components/Misc/LazyLoad'

import styles from './InstagramPostCard.module.scss'

import { THUMB } from 'DataObject/imagePrefixes'
import { imgPath } from 'DataObject/apiPaths'

export default function InstagramPostCard (props) {
  return (
    <div className={`text-center rounded mt-3 cursor-pointer ${styles.InstagramPostCard}`}>
      <div className="">
        <img
          src={`${imgPath}/${THUMB}_${props.instagramPost.img}`}
          alt="post"
        />
        {
          props.edit && 
          <LazyLoad
            loadableComponent={() => import(/* webpackChunkName: "EditInstagramPost" */ 'components/Admin/Instagram/EditInstagramPost')}
            instagramPost={props.instagramPost}
          />
        }
      </div>
    </div>
  )
}

InstagramPostCard.propTypes = {
  instagramPost: PropTypes.object,
  edit: PropTypes.bool
};
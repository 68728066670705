import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ChangeLang () {
  const { i18n } = useTranslation();
  const [defaultLang] = useState(i18n.language);
  const handleOnChange = (e) => {
    i18n.changeLanguage(e.target.value);
    localStorage.setItem('lang', e.target.value);
  };
  return (
    <select
      className="form-control form-control-sm"
      onChange={handleOnChange}
      defaultValue={defaultLang}
    >
      <option value="en">ENG</option>
      <option value="bs">BHS</option>
    </select>
  );
}

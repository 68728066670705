import http from './instance'
import { handleHttpError } from 'helpers/errors'

export function getCategoriesService () {
  return http.get('/categories').then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}

export function getAdminCategoryWithProductsService (id) {
  return http.get(`/admin/categories/${id}`).then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}

export function getCategoryWithProductsService (id, page = null) {
  const params = { page }
  return http.get(`/categories/${id}`, { params }).then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}

export function createCategoryService (data) {
  return http.post('/admin/categories', data, {timeout: 180000}).then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}

export function updateCategoryService (id, data) {
  return http.put(`/admin/categories/${id}`, data, {timeout: 180000}).then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}

export function deleteCategoryService (id) {
  return http.delete(`/admin/categories/${id}`).then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}
import React from 'react'

import FacebookIcon from 'svg/build/FacebookIcon'
import InstagramIcon from 'svg/build/InstagramIcon'

export default function SocialLinks () {
  return (
    <div className="d-flex justify-content-center">
      <a
        href="https://www.facebook.com/stranac-104541317941895"
        target="_blank"
        rel="noopener noreferrer"
        className="mr-3"
      >
        <FacebookIcon />
      </a>
      <a
        href="https://www.instagram.com/st.ranac"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramIcon />
      </a>
    </div>
  )
}
import * as React from "react";

function SvgFacebookIcon(props) {
  return (
    <svg width={36.016} height={36.016} {...props}>
      <path
        data-name="Path 1016"
        d="M0 0v36.015h36.017V-.001zm24.293 10.627h-3.591c-.424 0-.9.559-.9 1.306v2.593h4.489v3.7h-4.489v11.1h-4.238v-11.1h-3.843v-3.7h3.843v-2.175c0-3.12 2.165-5.657 5.137-5.657h3.591z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgFacebookIcon;

import React, { useEffect, useState } from 'react'

import styles from './InstagramFeed.module.scss'

import InstagramIcon from 'svg/build/InstagramIcon'
import InstagramPostCard from 'components/Instagram/InstagramPostCard'

import { getInstagramPostForUsersListService } from 'services/instagramService'

export default function InstagramFeed () {
  const [instagramPostList, setInstagramPostList] = useState(null);

  useEffect(() => {
    const getInstagramPostList = async () => {
      const response = await getInstagramPostForUsersListService();
      if(response.error)
        return;

      setInstagramPostList(response.data);
    };

    getInstagramPostList();
  }, []);
  return (
    <div className={`${styles.Outer} py-5`}>
      <div className="container position-relative">
        <div className="d-flex justify-content-center">
          <a
            href="https://www.instagram.com/st.ranac"
            target="_blank"
            rel="noopener noreferrer"
            className="mr-3"
          >
            <InstagramIcon color='#000' />
          </a>
          <h3 className="text-center text-uppercase">
            Follow us on IG
          </h3>
        </div>
        {instagramPostList && 
          <div className={`d-flex justify-content-center mt-3`}>
            <div className={`${styles.InstagramPostList}`}>
              <div className={`row`}>
                {
                  instagramPostList.map((item) =>
                    <a
                      className="col-4"
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={item.id}
                    >
                      <InstagramPostCard
                        instagramPost={item}
                      />
                    </a>
                  )
                }
              </div>
            </div>
          </div>
        }
        <div className="mt-5 text-center">
          <a
            href="https://www.instagram.com/st.ranac"
            target="_blank"
            rel="noopener noreferrer"
            className="mr-3"
          >
            <button className="btn btn-dark btn-black text-uppercase px-5">
              st.ranac
            </button>
          </a>
        </div>
        <div className={`${styles.ShapesLeft} d-none d-sm-block`}>
          <img src="/img/instagram/shapes-left.png" />
        </div>
        {instagramPostList && 
          <div className={`${styles.ShapesRight} d-none d-sm-block`}>
            <img src="/img/instagram/shapes-right.png" />
          </div>
        }
      </div>
    </div>
  )
}
import React from 'react'

import { Link } from 'react-router-dom'

import styles from './Footer.module.scss'

import { useSelector } from 'react-redux'
import { selectIsPhoneOrTablet } from 'store/slices/deviceSlice'

import LeftNavLinks from 'components/Navigation/LeftNavLinks'
import NavigationLinks from 'components/Navigation/NavigationLinks'
import SocialLinks from 'components/Misc/SocialLinks'

export default function Footer () {
  const isPhoneOrTablet = useSelector(selectIsPhoneOrTablet);

  return (
    <div className={`py-4 ${styles.FooterOuter}`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 text-center text-lg-left">
            <Link
              to="/"
              className={`mb-0 h1 font-weight-bold ${styles.Brand}`}
            >
              <img
                src={`/img/${isPhoneOrTablet ? 'ldpi-logo-inverse.png' : 'logo-inverse.png'}`}
                alt="logo"
              />
            </Link>
          </div>
          <div className="col-12 col-lg-8">
            {isPhoneOrTablet &&
              <div className="mt-5">
                <LeftNavLinks />
                <div className="mt-5">
                  <SocialLinks />
                </div>
              </div>
            }
            {!isPhoneOrTablet &&
              <div className="d-flex align-items-center h-100">
                <div className="navbar navbar-expand-sm">
                  <NavigationLinks
                    addSpacing={true}
                    showLang={false}
                  />
                </div>
                <div className="ml-auto">
                  <SocialLinks /> 
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
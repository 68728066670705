import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  editOrder: null,
  toggleRefresh: false
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setEditOrder: (state, action) => {
      state.editOrder = action.payload;
    },
    toggleRefresh: (state) => {
      state.toggleRefresh = !state.toggleRefresh;
    },
    reset: () => initialState
  },
});

export const { setEditOrder, toggleRefresh } = orderSlice.actions;

export const selectEditOrder = state => state.order.editOrder;
export const selectToggleRefresh = state => state.order.toggleRefresh;

export default orderSlice.reducer;

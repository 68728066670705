import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import styles from './AddToCart.module.scss'

import { useDispatch } from 'react-redux';
import { addItemAsync } from 'store/slices/cartSlice'


export default function AddToCart (props) {
  const [amount, setAmount] = useState(props.defaultAmount ? props.defaultAmount : 1);
  const dispatch = useDispatch();

  const setItemAmount = async (value) => {
    let parsedValue;
    if(!value || value < 1)
      parsedValue = 1;
    else if(value > 10)
      parsedValue = 10
    else
      parsedValue = parseInt(value);

    setAmount(parsedValue);
    if(props.hideButton)
      addToStore(parsedValue);
  }

  useEffect(() => {
    setAmount(props.defaultAmount ? props.defaultAmount : 1);
  }, [props.product])

  const addToStore = (forceAmount = false) => {
    const parsedAmount = forceAmount ? forceAmount : amount;
    dispatch(addItemAsync({id: props.product.id, price: props.product.price, name: props.product.name, img: props.product.img, amount: parsedAmount}))
  }
  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className={`${styles.InputGroup} d-flex align-items-center`}>
        <input
          type="button"
          value="-"
          className={`${styles.ButtonMinus}`}
          onClick={() => setItemAmount(amount-1)}
        />
        <input
          type="number"
          step="1"
          min="1"
          max="100"
          value={amount}
          onChange={e => setItemAmount(e.target.value)}
          name="quantity"
          className={`${styles.Quantity}`}
        />
        <input
          type="button"
          value="+"
          className={`${styles.ButtonPlus}`}
          onClick={() => setItemAmount(amount+1)}
        />
      </div>
      { !props.hideButton &&
        <button
          className={`btn btn-dark text-uppercase btn-black`}
          onClick={() => addToStore()}
        > Add to cart </button>
      }
    </div>
  )
}

AddToCart.propTypes = {
  product: PropTypes.object,
  defaultAmount: PropTypes.number,
  hideButton: PropTypes.bool
};
import { createSlice } from '@reduxjs/toolkit'
import { setId, setName } from 'store/slices/userSlice'
import { loginService, logoutService, reInitHttpHeader } from 'services/authService'

const initialState = {
  isLoggedIn: !!localStorage.getItem('token')
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    logout: state => {
      state.isLoggedIn = false;
    },
    reset: () => initialState
  },
});

export const { setIsLoggedIn, reset } = authSlice.actions;

export const loginAsync = (data) => async dispatch => {
  const response = await loginService(data);
  if (response.error)
    return response;

  dispatch(setIsLoggedIn(true));
  dispatch(setId(response.data.id));
  dispatch(setName(response.data.name));
  localStorage.setItem('token', response.tokenData.token);
  reInitHttpHeader();

  return response;
};

export const logoutAsync = () => async dispatch => {
  const response = await logoutService();
  dispatch(setIsLoggedIn(false));
  localStorage.removeItem('token');
  reInitHttpHeader();

  return response;
}

export const selectIsLoggedIn = state => state.auth.isLoggedIn;

export default authSlice.reducer;

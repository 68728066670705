import http from './instance'
import { handleHttpError } from 'helpers/errors'


export function getInstagramPostForUsersListService () {
  return http.get('/instagram').then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}
export function getInstagramPostListService () {
  return http.get('/admin/instagram').then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}

export function getInstagramPostService (id) {
  return http.get(`/admin/instagram/${id}`).then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}

export function createInstagramPostService (data) {
  return http.post('/admin/instagram', data, {timeout: 180000}).then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}

export function updateInstagramPostService (id, data) {
  return http.put(`/admin/instagram/${id}`, data, {timeout: 180000}).then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}

export function deleteInstagramPostService (id) {
  return http.delete(`/admin/instagram/${id}`).then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}
import http from './instance'
import { handleHttpError } from 'helpers/errors'

export function loginService (data) {
  return http.post('/auth/login', data).then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}

export function logoutService () {
  return http.post('/auth/logout').then(response => {
    return response.data;
  }).catch((e) => {
    return handleHttpError(e);
  });
}

export function reInitHttpHeader () {
  const token = localStorage.getItem('token');
  if (token)
    return http.defaults.headers.common['Authorization'] = localStorage.getItem('token');

  return delete http.defaults.headers.common['Authorization'];
}
import * as React from "react";

function SvgCloseIcon(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 283.34 283.34" {...props}>
      <rect
        x={265.19}
        y={201.93}
        width={81.61}
        height={366.9}
        rx={40.81}
        transform="rotate(-45 -70.35 461.89)"
      />
      <rect
        x={265.19}
        y={201.93}
        width={81.61}
        height={366.9}
        rx={40.81}
        transform="rotate(-135 173.361 297.56)"
      />
    </svg>
  );
}

export default SvgCloseIcon;

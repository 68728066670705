import * as React from "react";

function SvgCartIcon(props) {
  return (
    <svg width={18.659} height={19.999} {...props}>
      <path
        data-name="Path 1017"
        d="M3.995 4.001c0-2.219 1.316-3.958 3.254-4a3.257 3.257 0 011.876.525 3.337 3.337 0 011.273 1.5 4.689 4.689 0 01.26 1.956h2.648a2.119 2.119 0 00-1.462-2.586 2.309 2.309 0 00-.513-.068.674.674 0 01-.664-.666.667.667 0 01.682-.665 3.331 3.331 0 013.27 2.823c.047.378.043.762.064 1.178h1.888a.676.676 0 01.76.7q.26 2.862.525 5.724c.146 1.6.287 3.208.433 4.813.118 1.292.234 2.585.359 3.878.058.6-.2.889-.8.889H.803c-.6 0-.856-.293-.8-.889.141-1.486.272-2.974.408-4.459l.441-4.812q.2-2.165.391-4.334c.027-.3.051-.609.085-.913a.662.662 0 01.689-.591h1.729zm-1.4 1.336L1.383 18.655h9.331c.4-4.442.807-8.872 1.211-13.314h-1.267v1.984a.662.662 0 01-.654.672.663.663 0 01-.672-.675c-.005-.368 0-.737 0-1.1v-.875H5.327v1.945a.668.668 0 01-.66.709.667.667 0 01-.668-.7V5.342zM17.27 18.659L16.058 5.34h-2.784l-1.211 13.315h1.944v-3.3h1.33v3.309zM9.325 3.991v-.815a2.006 2.006 0 00-1.919-1.847c-1.24-.031-2.049.975-2.049 2.662z"
      />
    </svg>
  );
}

export default SvgCartIcon;

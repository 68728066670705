import React, { Suspense, lazy } from 'react'
import PropTypes from 'prop-types'

export default function LazyLoad (props) {
  const {loadableComponent, ...otherProps} = props;
  const Component = lazy(() => loadableComponent());

  return (
    <Suspense fallback={null}>
      <Component {...otherProps} />
    </Suspense>
  )
}

LazyLoad.propTypes = {
  loadableComponent: PropTypes.func
};
import React from 'react'
import PropTypes from 'prop-types'

import { Link, NavLink  } from 'react-router-dom'
import ChangeLang from './ChangeLang'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { selectIsLoggedIn, logoutAsync } from 'store/slices/authSlice'

export default function NavigationLinks (props) {
  const { t } = useTranslation();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  const handleLogout = async () => {
    await dispatch(logoutAsync());
  }
  const linkClass=`nav-item ${props.addSpacing ? 'mr-3' : ''}`;

  return (
    <React.Fragment>
      <ul className="navbar-nav m-auto ml-5 text-uppercase">
        <li className={linkClass}>
          <NavLink 
            exact={true}
            activeClassName='active-link'
            to="/"
          >
            {t('navigation.home')}
          </NavLink >
        </li>
        <li className={linkClass}>
          <NavLink 
            exact={true}
            activeClassName='active-link'
            to="/categories"
          >{t('navigation.products')}</NavLink >
        </li>
        {/* <li className={linkClass}>
          <NavLink 
            exact={true}
            activeClassName='active-link'
            to="/collaboration"
          >{t('navigation.collaboration')}</NavLink >
        </li> */}
        <li className={linkClass}>
          <NavLink 
            exact={true}
            activeClassName='active-link'
            to="/about"
          >{t('navigation.about')}</NavLink >
        </li>
        <li  className={linkClass}>
          <NavLink 
            exact={true}
            activeClassName='active-link'
            to="/faqs"
          >{t('navigation.faqs')}</NavLink >
        </li>
        <li className="nav-item">
          <NavLink 
            exact={true}
            activeClassName='active-link'
            to="/contact"
          >{t('navigation.contact')}</NavLink >
        </li>
        {
          isLoggedIn &&
            <li className="nav-item ml-3">
              <Link to="/admin">Admin</Link>
            </li>
        }
      </ul>
      {
        props.showLang &&
          <span className="navbar-text">
            <ChangeLang />
          </span>
      }
      {
        isLoggedIn &&
          <span
            className="navbar-text ml-3 cursor-pointer"
            onClick={handleLogout}
          >
            Logout
          </span>
      }
    </React.Fragment>
  )
}

NavigationLinks.propTypes = {
  addSpacing: PropTypes.bool,
  showLang: PropTypes.bool
};
import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux';

import styles from './CartProduct.module.scss'

import AddToCart from 'components/Product/AddToCart'
import CloseIcon from 'svg/build/CloseIcon'
import { removeItem } from 'store/slices/cartSlice';

import { THUMB } from 'DataObject/imagePrefixes'
import { imgPath } from 'DataObject/apiPaths'

export default function CartProduct (props) {
  const dispatch = useDispatch();

  const removeItemFromCart = () => {
    dispatch(removeItem(props.product.id));
  }
  return (
    <div className={`position-relative ${styles.CartProductOuter}`}>
      <div className="row">
        <div className="col-4 col-md-3">
          <img
            src={`${imgPath}/${THUMB}_${props.product.img}`}
            alt="product"
          />
        </div>
        <div className={`col-8 col-md-9`}>
          <div className={`row`}>
            <div className="col-10">
              <div className={`${styles.Title}`}>
                {props.product.name}
              </div>
            </div>
            <div className="col-12 mt-2">
              <div className={`${styles.AddToCartOuter}`}>
                <AddToCart
                  product={props.product}
                  defaultAmount={props.product.amount}
                  hideButton={true}
                />
              </div>
              <span className={`${styles.Price} mt-2 mt-md-0 mt-lg-0`}>
                {(props.product.amount * props.product.price).toFixed(2)} BAM
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.CloseButton} cursor-pointer`}
        onClick={removeItemFromCart}
      >
        <CloseIcon />
      </div>
    </div>
  )
}

CartProduct.propTypes = {
  product: PropTypes.object
};
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import * as contentType from 'DataObject/modalContent'

import LazyLoad from 'components/Misc/LazyLoad'

export default function ModalBody (props) {
  const [component, setComponent] = useState(null);

  useEffect(() => {
    const renderContent = () => {
      switch(props.content) {
      case contentType.CategoryForm:
        return <LazyLoad loadableComponent={() => import(/* webpackChunkName: "CategoryForm" */ 'components/Admin/Categories/CategoryForm')} />;
      case contentType.ProductForm:
        return <LazyLoad loadableComponent={() => import(/* webpackChunkName: "ProductForm" */ 'components/Admin/Products/ProductForm')} />;
      case contentType.InstagramPostForm:
        return <LazyLoad loadableComponent={() => import(/* webpackChunkName: "InstagramPostForm" */ 'components/Admin/Instagram/InstagramPostForm')} />;
      case contentType.EditOrderForm:
        return <LazyLoad loadableComponent={() => import(/* webpackChunkName: "EditOrderForm" */ 'components/Admin/Orders/EditOrderForm')} />;
      default:
        return '';
      }
    }

    setComponent(renderContent());
  }, [props.content])
  return (
    <div>
      {component}
    </div>
  )
}

ModalBody.propTypes = {
  content: PropTypes.string
};
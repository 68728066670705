import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';

export default function ConfirmationButtons (props) {
  const { t } = useTranslation();
  return (
    <div>
      {
        props.onConfirm && 
          <button
            className="btn btn-success"
            onClick={props.onConfirm}
          >
            { t('modal.confirm') }
          </button>
      }
      {
        props.onCancel && 
          <button
            className="btn btn-secondary"
            onClick={props.onCancel}
          >
            { t('modal.cancel') }
          </button>
      }
    </div>
  )
}

ConfirmationButtons.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}
import * as React from "react";

function SvgHamburgerIcon(props) {
  return (
    <svg
      id="hamburger_icon_svg__\u0421\u043B\u043E\u0439_1"
      viewBox="0 0 139 139"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".hamburger_icon_svg__st0{fill:none;stroke:#000;stroke-width:6;stroke-miterlimit:10}"
        }
      </style>
      <path
        className="hamburger_icon_svg__st0"
        id="hamburger_icon_svg__XMLID_6_"
        d="M26.5 46.3h86"
      />
      <path
        className="hamburger_icon_svg__st0"
        id="hamburger_icon_svg__XMLID_9_"
        d="M26.5 92.7h86"
      />
      <path
        className="hamburger_icon_svg__st0"
        id="hamburger_icon_svg__XMLID_8_"
        d="M26.5 69.5h86"
      />
    </svg>
  );
}

export default SvgHamburgerIcon;

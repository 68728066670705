import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  leftNavOpened: false,
  cartOpened: false
};

export const uiSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    toggleLeftNavOpened: state => {
      state.leftNavOpened = !state.leftNavOpened;
      if(state.leftNavOpened) {
        document.body.style.overflow = 'hidden';
        state.cartOpened = false;
      }
      else
        document.body.style.overflow = 'auto';
    },
    closeLeftNavigation: state => {
      state.leftNavOpened = false;
      document.body.style.overflow = 'auto';
    },
    toggleCartOpened: state => {
      state.cartOpened = !state.cartOpened;
      if(state.cartOpened) {
        document.body.style.overflow = 'hidden';
        state.leftNavOpened = false;
      }
      else
        document.body.style.overflow = 'auto';
    },
    openCart: state => {
      state.cartOpened = true;
      document.body.style.overflow = 'hidden';
      state.leftNavOpened = false;
    },
    closeCart: state => {
      state.cartOpened = false;
      document.body.style.overflow = 'auto';
    },
    reset: () => initialState
  },
});

export const { toggleLeftNavOpened, closeLeftNavigation, toggleCartOpened, openCart, closeCart, reset } = uiSlice.actions;

export const selectLeftNavOpened = state => state.ui.leftNavOpened;
export const selectCartOpened = state => state.ui.cartOpened;

export default uiSlice.reducer;

import React from 'react'
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux';
import { closeCart, selectCartOpened } from 'store/slices/uiSlice';

import styles from './CartNavigation.module.scss'

import CloseIcon from 'svg/build/CloseIcon'
import CartTracker from 'components/Cart/CartTracker'
import CartProduct from 'components/Cart/CartNavigation/CartProduct'
import { selectData, selectTotalAmount, selectTotalCost } from 'store/slices/cartSlice';

export default function CartNavigation () {
  const cartOpened = useSelector(selectCartOpened);
  const list = useSelector(selectData);
  const totalItems = useSelector(selectTotalAmount);
  const totalCost = useSelector(selectTotalCost);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeCart());
  }
  return (
    <div className={`py-5 ${styles.CartOuter} ${cartOpened ? styles.CartOuterActive : ''}`}>
      <div className="container px-5 position-relative">
        <div>
          <span className={`${styles.Title}`}>Cart</span>
          <span className="ml-4">
            <CartTracker />
          </span>
        </div>
        <div
          className={`${styles.CloseButton} cursor-pointer`}
          onClick={close}
        >
          <CloseIcon className="icon" />
        </div>
      </div>
      <div className="container mt-2 px-5">
        {
          totalItems == 0 &&
          <h3 className="mt-5 text-center">
            Cart is empty
          </h3>
        }
        {
          totalItems != 0 &&
          <div>
            <div className={`${styles.ProductList}`}>
              {
                Object.keys(list).map(key => 
                  <div
                    className="mt-4 pb-4 design-border-bottom--light"
                    key={key}
                  >
                    <CartProduct product={list[key]} />
                  </div>
                )
              }
            </div>
            <div className={`${styles.Title} mt-5 row`}> 
              <div className="col-6">
                Subtotal
              </div>
              <div className="col-6 text-right">
                {totalCost.toFixed(2)} BAM
              </div>
              <div className="col-12 mt-3">
                <Link to="/checkout">
                  <button className="btn btn-dark btn-black w-100 text-uppercase">
                    Checkout
                  </button>
                </Link>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}
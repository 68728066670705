import React from 'react'
import { Link } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { selectIsPhoneOrTablet } from 'store/slices/deviceSlice'
import { toggleLeftNavOpened, selectLeftNavOpened, toggleCartOpened } from 'store/slices/uiSlice'

import CartTracker from 'components/Cart/CartTracker'
import HamburgerIcon from 'svg/build/HamburgerIcon'
import NavigationLinks from './NavigationLinks'
import styles from './Navigation.module.scss'

export default function Navigation () {
  const leftNavOpened = useSelector(selectLeftNavOpened);
  const isPhoneOrTablet = useSelector(selectIsPhoneOrTablet);
  const dispatch = useDispatch();
  const openLeftNav = () => {
    setTimeout(() => {
      dispatch(toggleLeftNavOpened());
    }, 0);
  }
  const openCart = () => {
    setTimeout(() => {
      dispatch(toggleCartOpened());
    }, 0);
  }
  return (
    <nav className={`navbar navbar-expand-sm bg-white fixed-top shadow-sm ${styles.nav} ${leftNavOpened ? styles.MoveRight : ''}`}>
      <div className="container">
        {
          isPhoneOrTablet &&
            <HamburgerIcon
              className="icon mr-2"
              onClick={openLeftNav}
            />
        }
        <Link
          to="/"
          className={`navbar-brand mb-0 h1 font-weight-bold ${styles.brand}`}
        >
          <img
            src="/logo.jpg"
            alt="logo"
          />
        </Link>
        {
          !isPhoneOrTablet &&
            <NavigationLinks
              addSpacing={true}
              showLang={false}
            />
        }
        <span
          className="ml-lg-4 cursor-pointer"
          onClick={openCart}
        >
          <CartTracker />
        </span>
      </div>
    </nav>
  );
}

import React from 'react'
import { NavLink } from 'react-router-dom'

//import ChangeLang from './ChangeLang'

import { useSelector } from 'react-redux'
import { selectLeftNavOpened } from 'store/slices/uiSlice'
import { useTranslation } from 'react-i18next'

import styles from './LeftNav.module.scss'

export default function LeftNav () {
  const { t } = useTranslation();
  const leftNavOpened = useSelector(selectLeftNavOpened);

  return (
    <div
      className={`py-5 ${styles['left-nav-outer']} ${leftNavOpened ? styles['left-nav-outer--active'] : ''}`}
    >
      <ul className="navbar-nav mt-3 m-auto text-uppercase text-center">
        <li className="nav-item mt-2">
          <NavLink 
            exact={true}
            activeClassName='active-link'
            to="/"
          >
            {t('navigation.home')}
          </NavLink >
        </li>
        <li className="nav-item mt-2">
          <NavLink 
            exact={true}
            activeClassName='active-link'
            to="/categories"
          >{t('navigation.products')}</NavLink >
        </li>
        {/* <li className="nav-item mt-2">
          <NavLink 
            exact={true}
            activeClassName='active-link'
            to="/collaboration"
          >{t('navigation.collaboration')}</NavLink >
        </li> */}
        <li className="nav-item mt-2">
          <NavLink 
            exact={true}
            activeClassName='active-link'
            to="/about"
          >{t('navigation.about')}</NavLink >
        </li>
        <li className="nav-item mt-2">
          <NavLink 
            exact={true}
            activeClassName='active-link'
            to="/faqs"
          >{t('navigation.faqs')}</NavLink >
        </li>
        <li className="nav-item mt-2">
          <NavLink 
            exact={true}
            activeClassName='active-link'
            to="/contact"
          >{t('navigation.contact')}</NavLink >
        </li>
        {/* <li className="nav-item w-50 mt-5 mx-auto">
          <ChangeLang />
        </li> */}
      </ul>
    </div>
  )
}
import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { selectTitle, selectContent, selectOnConfirm, reset, selectOnCancel, selectOnClose } from 'store/slices/modalSlice'

import CloseIcon from 'svg/build/CloseIcon'
import ModalBody from './ModalBody'
import ConfirmationButtons from './ConfirmationButtons'

import styles from './Modal.module.scss'

export default function Modal () {
  const [opacity, setOpacity] = useState(0);
  const title = useSelector(selectTitle);
  const content = useSelector(selectContent);
  const onConfirm = useSelector(selectOnConfirm);
  const onCancel = useSelector(selectOnCancel);
  const onClose = useSelector(selectOnClose);

  const dispatch = useDispatch();

  const closeModal = () => {
    setOpacity(0);
    if(onClose)
      dispatch(onClose);
      
    setTimeout(() => {
      dispatch(reset());
    }, 300);
  }
  useEffect(() => {
    function updateOpacity () {
      setTimeout(() => {
        setOpacity(title ? 1 : 0);
      }, 0);
    }
    updateOpacity();
  }, [title])
  return (
    <div
      className={`${styles['modal-mask']} ${title ? '' : styles['modal-mask--hidden']} ${opacity ? '' : styles['modal-mask--no-opacity']}`}
    >
      {
        title && 
      <div
        className={`${styles['modal-wrapper']}`}
      >
        <div
          className={`${styles['modal-container']}`}
        >
          <div className={`${styles['modal-header']}`}>
            <slot name="header">
              <b>{ title }</b> 
              <CloseIcon
                name="close"
                className={`${styles['close-button']}`}
                onClick={closeModal}
              />
            </slot>
          </div>
          {
            content ? 
              <div className="modal-body">
                <ModalBody content={content} />
              </div>
              : 
              ''
          }
          {
            (onConfirm || onCancel) &&
              <div className="modal-footer">
                <ConfirmationButtons
                  onConfirm={onConfirm}
                  onCancel={onCancel}
                />
              </div>
          }
        </div>
      </div>
      }
    </div>
  )
}
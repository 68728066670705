import React from 'react'

import { NavLink  } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

export default function NavigationLinks () {
  const { t } = useTranslation();

  return (
    <ul className="navbar-nav mt-3 m-auto text-uppercase text-center">
      <li className="nav-item mt-2">
        <NavLink 
          exact={true}
          activeClassName='active-link'
          to="/"
        >
          {t('navigation.home')}
        </NavLink >
      </li>
      <li className="nav-item mt-2">
        <NavLink 
          exact={true}
          activeClassName='active-link'
          to="/categories"
        >{t('navigation.products')}</NavLink >
      </li>
      {/* <li className="nav-item mt-2">
        <NavLink 
          exact={true}
          activeClassName='active-link'
          to="/collaboration"
        >{t('navigation.collaboration')}</NavLink >
      </li> */}
      <li className="nav-item mt-2">
        <NavLink 
          exact={true}
          activeClassName='active-link'
          to="/about"
        >{t('navigation.about')}</NavLink >
      </li>
      <li className="nav-item mt-2">
        <NavLink 
          exact={true}
          activeClassName='active-link'
          to="/faqs"
        >{t('navigation.faqs')}</NavLink >
      </li>
      <li className="nav-item mt-2">
        <NavLink 
          exact={true}
          activeClassName='active-link'
          to="/contact"
        >{t('navigation.contact')}</NavLink >
      </li>
    </ul>
  )
}